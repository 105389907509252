import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { SectionHero } from './careers/_hero';
import { SectionInfo } from './careers/_info';
import { SectionRoles } from './careers/_roles';
import { Layout } from '~/components/Layout';

const CareersPage: React.FC = ({ data }: any) => {
  return (
    <Layout>
      <Helmet>
        <title>Careers — Column</title>
        <meta name="description" content="We're on a mission to change the financial landscape." />
      </Helmet>
      <SectionHero />
      <SectionInfo />
      {/* Pass data as a prop */}
      <SectionRoles data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query OpenRoleIndexQuery {
    allContentfulOpenRole(sort: { fields: jobOrder, order: ASC }) {
      nodes {
        jobTitle
        jobSlug
        jobLink
        jobDescription {
          raw
        }
        jobOrder
      }
    }
  }
`;

export default CareersPage;
