import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Container } from '~/elements/Container';
import { InlineLink } from '~/elements/InlineLink';
import { Box } from '~/elements/Box';
import { List } from '~/elements/List';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

const RoleGroupTrigger = styled.summary`
  cursor: pointer;
  background-image: none;
  -webkit-appearance: none;
  outline: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }

  h4 {
    display: flex;
    gap: var(--space-2);
    align-items: center;
    padding-right: 32px;
  }
`;

const RoleGroup = styled.details`
  scroll-margin-top: 80px;

  &:last-child {
    border-bottom: 1px solid var(--color-gray-200);
  }
  .icon--chevron-down {
    transition: 0.1s transform;
  }

  &[open] {
    .icon--chevron-down {
      transform: rotate(180deg);
    }
    ${RoleGroupTrigger} {
      ${Box} {
        display: none;
      }
    }
  }
`;

const RoleTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-4) 0;
  border-top: 1px solid var(--color-gray-200);
`;

const StyledText = styled(Text)`
  p {
    margin-bottom: 24px;
  }
`;

export const SectionRoles: React.FC<{ data: any }> = ({ data }) => {
  const list = data.allContentfulOpenRole.nodes;
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      document.getElementById(window.location.hash.replace('#', ''))?.setAttribute('open', 'true');
    }
  }, []);

  return (
    <Section pt={{ _: 0, md: 0 }}>
      <Container>
        <Grid zIndex="10" position="relative">
          <Column gridColumn={{ _: '1 / span 12', md: '2 / span 7', lg: '2 / span 6' }}>
            <Grid gridTemplateColumns={1} gapY={8}>
              <Heading as="h2" weight="semibold" size="600">
                Open roles
              </Heading>
              <List>
                {list.map((post: any, index: number) => (
                  <>
                    <RoleGroup id={post.jobSlug}>
                      <RoleGroupTrigger onClick={() => history?.pushState(null, '', `#${post.jobSlug}`)}>
                        <RoleTitle>
                          <Heading as="h4" weight="semibold">
                            {post.jobTitle}
                          </Heading>
                          <Icon.ChevronDown />
                        </RoleTitle>
                      </RoleGroupTrigger>
                      <StyledText pr={8}>{post.jobDescription && renderRichText(post.jobDescription)}</StyledText>
                      <Text pb={8} pr={8}>
                        <InlineLink href={post.jobLink}>Please apply here</InlineLink>
                      </Text>
                    </RoleGroup>
                  </>
                ))}
              </List>
              <Text mt={8}>
                We're mostly hiring locally in the San Francisco Bay Area, however will be open to remote for
                exceptional candidates. Sadly we won't have the time to respond to everyone, but we do read every
                application we get. Please go through the application links, direct emails are strongly discouraged :)
              </Text>
              <Text mt={8}>
                <InlineLink href="/legal/notice-to-recruiters-and-staffing-agencies">
                  Notice to Recruiters & Staffing Agencies
                </InlineLink>
              </Text>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionRoles;
